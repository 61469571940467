import { BASE_URL } from "../../strings";
export const bikepartsSchemaSv = {
  keywords: ["AI för cykeldelar", "AI för växelöron", "AI för cykling", "AI för bromsar"],
  schemaMarkup: {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Premium Cykeldelar",
    "description": "Upptäck premium cykeldelar för att förbättra din cykelupplevelse.",
    "brand": {
      "@type": "Brand",
      "name": "BikeParts Inc."
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "SEK",
      "price": "0",
      "itemCondition": "http://schema.org/NewCondition",
      "availability": "http://schema.org/InStock"
    }
  }
}

export const schemaMarkupStartSv = {
  keywords: [
    "AI konsult",
    "AI-konsultering",
    "AI-utbildning",
    "AI-kurser",
    "AI-konsultering",
    "Framtidsteknologi",
    "Artificiell intelligens",
    "Maskininlärning",
    "Teknikkonsulting",
    "Digitala innovationer",
    "Teknikutbildning",
    "AI-implementering"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Konsultering och kurser inom AI, WEB3 och framtidens teknik",
    "description": "Vi erbjuder kurser inom AI samt konsultering för ditt företag. Utforska våra tjänster inom artificiell intelligens, WEB3 och framtidsteknologi.",
    "url": {BASE_URL},
    "mainEntity": {
      "@type": "Organization",
      "name": "techlära",
      "url": {BASE_URL},
      "description": "Konsulttjänster och utbildningar inom AI, WEB3 och framtidens teknik",
      "sameAs": [
        "https://www.linkedin.com/company/techlara",
        "https://www.facebook.com/motionapps.ab",
        "https://www.instagram.com/techlara.se"
      ]
    },
    "offers": {
      "@type": "Offer",
      "itemOffered": [
        {
          "@type": "Service",
          "name": "AI-kurser",
          "description": "Utbildningar inom artificiell intelligens"
        },
        {
          "@type": "Service",
          "name": "AI-konsultering",
          "description": "Expertrådgivning inom AI för företag"
        }
      ]
    }
  }
}

export const consultSchemaSv = {
  keywords: [
    "AI-konsultering Sverige",
    "AI-konsult",
    "AI-expert",
    "AI-rådgivning",
    "AI-implementering",
    "AI-strategi",
    "Dataanalys",
    "Företagsautomatisering",
    "Digitalisering",
    "AI-lösningar",
    "Affärsutveckling med AI",
    "Chatbots",
    "Naturlig språkbehandling",
    "Computer Vision",
    "AI-etik",
    "AI för företag",
    "Miljövänlig AI",
    "Skräddarsydda AI-tjänster",
    "AI konsult",
    "AI konsult Sverige",
    "AI konsult Stockholm",
    "AI konsult Göteborg",
    "AI konsult Malmö",
    "AI konsult Uppsala",
    "AI konsult Örebro",
    "AI konsult Linköping",
    "AI konsult Lund",
    "AI konsult Helsingborg",
    "AI konsult Malmö",
    "AI konsult Göteborg",
    "AI konsult Stockholm",
    "AI konsult Uppsala",
    "AI-konsultering",
    "AI-utbildning",
    "AI-rådgivning för företag",
    "AI-kurser",
    "Förläsningar om AI",
    "Artificiell intelligens",
    "Teknikkonsulting",
    "AI-implementering"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "AI-konsulttjänster",
    "description": "Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering, strategiutveckling och skräddarsydda AI-lösningar.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": {BASE_URL}
    },
    "serviceType": "AI-konsultering",
    "areaServed": "Sverige",
    "offers": {
      "@type": "Offer",
      "price": "Kontakta oss för prisuppgift",
      "priceCurrency": "SEK"
    }
  }
}

export const newsSchemaMarkupSv = {
  keywords: [
    "AI-nyheter",
    "Artificiell intelligens",
    "Teknologiuppdateringar",
    "Digitala trender",
    "Framtidsteknologi",
    "Tekniknyheter",
    "AI-utveckling",
    "Innovationer inom AI",
    "Svensk AI-information",
    "Teknologiska framsteg"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Samling av AI-nyheter",
    "description": "Läs om AI-nyheter, uppdateringar på siten och nytt på sociala medier",
    "url": `${BASE_URL}/ainyheter`,
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": []  // This will be populated dynamically
    }
  }
}

export const chatbotSchemaSv = {
  keywords: [
    "Chatbot utveckling",
    "AI chatbot företag",
    "AI chatbot",
    "Kundservice automation",
    "Företagschatbot",
    "Digital kundtjänst",
    "Chatbot integration",
    "Skräddarsydd chatbot",
    "Intelligent chatbot",
    "Chatbot lösningar",
    "Automatiserad kundservice"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Bygg din egen chatbot",
    "description": "Vi hjälper er att bygga en Chatbot för ditt företag.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": {BASE_URL}
    },
    "serviceType": "Bygg din egen chatbot",
    "areaServed": "Sverige",
    "keywords": [
      "Chatbot utveckling",
      "AI chatbot företag",
      "AI chatbot",
      "Kundservice automation",
      "Företagschatbot",
      "Digital kundtjänst",
      "Chatbot integration",
      "Skräddarsydd chatbot",
      "Intelligent chatbot",
      "Chatbot lösningar",
      "Automatiserad kundservice"
    ]
  }
}

export const productsSchemaMarkupSv = {
  keywords: [
    "AI-produkter",
    "artificiell intelligens",
    "maskininlärning",
    "techlara",
    "AI-tjänster"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "AI-produkter",
    "description": "Vi hjälper dig att skapa AI-produkter. Utforska våra tjänster inom artificiell intelligens och maskininlärning.",
    "keywords": "AI-produkter, artificiell intelligens, maskininlärning, techlara, AI-tjänster",
    "url": `${BASE_URL}/produkter`
  }
}