import React from 'react';
import './IconScroll.css';
import images from '../../constants/images';
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG, LANG_SV } from '../../constants/strings';

const Customers = () => {
    const { currentLanguage } = useLanguage();

    return (
        <div className="partner-container">
            {currentLanguage === LANG_ENG ? 'Customers we have helped:' : 'Kunder vi har hjälpt:'} 
            <div className="tscroll">
                <a href="https://cykelnatur.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.cykelNatur} alt="kund cykel natur" title="kund cykel natur" />
                </a>
                <a href="https://golow.eu/" target="_blank" rel="noopener noreferrer">
                    <img src={images.golowLogo} alt="kund golow" title="kund golow" />
                </a>
                <a href="https://www.mysallitaliana.com/" target="_blank" rel="noopener noreferrer">
                    <img src={images.mysItalia} alt="kund mys italia" title="kund mys italia" />
                </a>
                <a href="https://husstainability.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.husstainability} alt="kund husstainability" title="kund husstainability" />
                </a>
                <a href="https://lillastaderiet.se" target="_blank" rel="noopener noreferrer">
                    <img src={images.lillaStaderiet} alt="kund lilla staderiet" title="kund lilla staderiet" />
                </a>
            </div>
        </div>
    );
};

export default Customers;